import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import Logo from "../../../assets/images/BE-logo.png";
import login1 from "../../../assets/images/login/1.png";

const Privacy = () => {
  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper className="list-inline m-0 p-0">
                  <ul className="swiper-wrapper list-inline m-0 p-0">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3 w-75 h-75"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Welcome to Benevento East
                      </h2>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <img src={Logo} fluid className="w-25" alt="logo" />
              </div>
              <div
                className="iq-accordion career-style faq-style"
                style={{
                  maxHeight: "570px",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                <Card className="iq-accordion-block accordion-active p-0">
                  <Card.Body className="active-faq d-flex justify-content-between pb-0 mb-0">
                    <div className="header-title mb-0">
                      <h2 className="card-title text-primary">
                        Privacy Policy
                      </h2>
                      <p>Last updated: June 14, 2021 </p>
                      <p className="mb-0 p-0 mt-0">
                        This Privacy Policy describes Our policies and
                        procedures on the collection, use, and disclosure of
                        Your information when You use the Service and tells You
                        about Your privacy rights and how the law protects You.
                        <br />
                        <br /> We use Your Personal data to provide and improve
                        the Service. By using the Service, You agree to the
                        collection and use of information in accordance with
                        this Privacy Policy.
                      </p>
                      <h4
                        className="card-title text-primary mt-4 mb-2"
                        style={{ textTransform: "none" }}
                      >
                        Interpretation and Definitions
                      </h4>
                      <h6 className="card-title text-primary">
                        Interpretation
                      </h6>

                      <p className="mb-0 p-0 mt-0">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or in
                        plural.
                      </p>
                      <h6 className="card-title text-primary mt-3">
                        Definitions
                      </h6>
                      <p>For the purposes of this Privacy Policy:</p>

                      <dl className="row ">
                        <dt className="col-sm-3">Account</dt>
                        <dd className="col-sm-9">
                          means a unique account created for You to access our
                          Service or parts of our Service
                        </dd>

                        <dt className="col-sm-3">Company</dt>
                        <dd className="col-sm-9">
                          (referred to as either "the Company", "We", "Us" or
                          "Our" in this Agreement) refers to Benevento East HOA
                          5016 Spedale Court #304, Spring Hill, TN 37174.
                        </dd>

                        <dt className="col-sm-3">Cookies</dt>
                        <dd className="col-sm-9">
                          are small files that are placed on Your computer,
                          mobile device or any other device by a website,
                          containing the details of Your browsing history on
                          that website among its many uses
                        </dd>

                        <dt className="col-sm-3 ">Country</dt>
                        <dd className="col-sm-9">
                          refers to: Tennessee, United States
                        </dd>

                        <dt className="col-sm-3 ">Device</dt>
                        <dd className="col-sm-9">
                          means any device that can access the Service such as a
                          computer, a cellphone or a digital tablet
                        </dd>

                        <dt className="col-sm-3 ">Personal Data</dt>
                        <dd className="col-sm-9">
                          is any information that relates to an identified or
                          identifiable individual.
                        </dd>

                        <dt className="col-sm-3 ">Service</dt>
                        <dd className="col-sm-9">refers to the Website.</dd>

                        <dt className="col-sm-3 ">Service Provider</dt>
                        <dd className="col-sm-9">
                          means any natural or legal person who processes the
                          data on behalf of the Company. It refers to
                          third-party companies or individuals employed by the
                          Company to facilitate the Service, to provide the
                          Service on behalf of the Company, to perform services
                          related to the Service or to assist the Company in
                          analyzing how the Service is used.
                        </dd>

                        <dt className="col-sm-3 ">Usage Data</dt>
                        <dd className="col-sm-9">
                          refers to data collected automatically, either
                          generated by the use of the Service or from the
                          Service infrastructure itself (for example, the
                          duration of a page visit).
                        </dd>

                        <dt className="col-sm-3 ">Website</dt>
                        <dd className="col-sm-9">
                          refers to Benevento East, accessible from {"  "}
                          <Link to="">https://www.beneventoeast.com</Link>
                        </dd>

                        <dt className="col-sm-3 ">You</dt>
                        <dd className="col-sm-9">
                          means the individual accessing or using the Service,
                          or the company, or other legal entity on behalf of
                          which such individual is accessing or using the
                          Service, as applicable.
                        </dd>
                      </dl>

                      <h4
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Collecting and Using Your Personal Data
                      </h4>
                      <h5
                        className="card-title text-primary"
                        style={{ textTransform: "none" }}
                      >
                        Types of Data Collected
                      </h5>
                      <h6 className="card-title text-primary">Personal Data</h6>
                      <p className="mb-0 p-0 mt-0">
                        While using Our Service, We may ask You to provide Us
                        with certain personally identifiable information that
                        can be used to contact or identify You. Personally
                        identifiable information may include, but is not limited
                        to:
                      </p>
                      <ul>
                        <li>Email address</li>
                        <li>First name and last name </li>
                        <li>Phone number </li>
                        <li>
                          Address, State, Province, ZIP/Postal code, City{" "}
                        </li>
                        <li>Monthly / Maintenace Fees </li>
                        <li>ARCs </li>
                        <li>HOA updates, minutes, and notices and fines</li>
                        <li>Financial standing for HOA account</li>
                      </ul>
                      <h6 className="card-title text-primary">Usage Data</h6>
                      <p>
                        Usage Data is collected automatically when using the
                        Service.
                      </p>
                      <p>
                        Usage Data may include information such as Your Device's
                        Internet Protocol address (e.g. IP address), browser
                        type, browser version, the pages of our Service that You
                        visit, the time and date of Your visit, the time spent
                        on those pages, unique device identifiers and other
                        diagnostic data.
                        <p />
                        <p>
                          When You access the Service by or through a mobile
                          device, We may collect certain information
                          automatically, including, but not limited to, the type
                          of mobile device You use, Your mobile device unique
                          ID, the IP address of Your mobile device, Your mobile
                          operating system, the type of mobile Internet browser
                          You use, unique device identifiers and other
                          diagnostic data.
                        </p>
                        <p>
                          We may also collect information that Your browser
                          sends whenever You visit our Service or when You
                          access the Service by or through a mobile device.
                        </p>
                      </p>
                      <h6 className="card-title text-primary">
                        Tracking Technologies and Cookies
                      </h6>
                      <p>
                        We use Cookies and similar tracking technologies to
                        track the activity on Our Service and store certain
                        information. Tracking technologies used are beacons,
                        tags, and scripts to collect and track information and
                        to improve and analyze Our Service. The technologies We
                        use may include:
                      </p>
                      <dl className="row ">
                        <dt className="col-sm-3">Cookies or Browser Cookies</dt>
                        <dd className="col-sm-9">
                          A cookie is a small file placed on Your Device. You
                          can instruct Your browser to refuse all Cookies or to
                          indicate when a Cookie is being sent. However, if You
                          do not accept Cookies, You may not be able to use some
                          parts of our Service. Unless you have adjusted Your
                          browser setting so that it will refuse Cookies, our
                          Service may use Cookies.
                        </dd>
                        <dt className="col-sm-3">Flash Cookies</dt>
                        <dd className="col-sm-9">
                          Certain features of our Service may use local stored
                          objects (or Flash Cookies) to collect and store
                          information about Your preferences or Your activity on
                          our Service. Flash Cookies are not managed by the same
                          browser settings as those used for Browser Cookies.
                        </dd>
                        <dt className="col-sm-3">Web Beacons</dt>
                        <dd className="col-sm-9">
                          Certain sections of our Service and our emails may
                          contain small electronic files known as web beacons
                          (also referred to as clear gifs, pixel tags, and
                          single-pixel gifs) that permit the Company, for
                          example, to count users who have visited those pages
                          or opened an email and for other related website
                          statistics (for example, recording the popularity of a
                          certain section and verifying system and server
                          integrity).
                        </dd>
                      </dl>
                      <p>
                        Cookies can be "Persistent" or "Session" Cookies.
                        Persistent Cookies remain on Your personal computer or
                        mobile device when You go offline, while Session Cookies
                        are deleted as soon as You close Your web browser.{" "}
                      </p>
                      <p>
                        We use both Session and Persistent Cookies for the
                        purposes set out below:
                      </p>
                      <dl className="row ">
                        <dt className="col-sm-3">
                          Necessary / Essential Cookies
                        </dt>
                        <dd className="col-sm-9">
                          <p>Type: Session Cookies</p>
                          <p>Administered by: Us</p>
                          <p>
                            Purpose: These Cookies are essential to provide You
                            with services available through the Website and to
                            enable You to use some of its features. They help to
                            authenticate users and prevent fraudulent use of
                            user accounts. Without these Cookies, the services
                            that You have asked for cannot be provided, and We
                            only use these Cookies to provide You with those
                            services.
                          </p>
                        </dd>
                        <dt className="col-sm-3">
                          Cookies Policy / Notice Acceptance Cookies
                        </dt>
                        <dd className="col-sm-9">
                          <p>Type: Persistent Cookies</p>
                          <p>Administered by: Us</p>
                          <p>
                            Purpose: These Cookies identify if users have
                            accepted the use of cookies on the Website.
                          </p>
                        </dd>

                        <dt className="col-sm-3"> Functionality Cookies</dt>
                        <dd className="col-sm-9">
                          <p>Type: Persistent Cookies</p>
                          <p>Administered by: Us</p>
                          <p>
                            Purpose: These Cookies allow us to remember choices
                            You make when You use the Website, such as
                            remembering your login details or language
                            preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to
                            avoid You having to re-enter your preferences every
                            time You use the Website.
                          </p>
                        </dd>
                      </dl>
                      <p>
                        For more information about the cookies we use and your
                        choices regarding cookies, please visit our Cookies
                        Policy or the Cookies section of our Privacy Policy.
                      </p>
                      <h4
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Use of Your Personal Data
                      </h4>
                      <p>
                        The Company may use Personal Data for the following
                        purposes:
                      </p>
                      <ul>
                        <li>
                          <span className="fw-bold">
                            To provide and maintain our Service,
                          </span>
                          {"  "}
                          including to monitor the usage of our Service
                        </li>

                        <li>
                          <span className="fw-bold">
                            To manage Your Account:
                          </span>
                          {"  "}
                          to manage Your registration as a user of the Service.
                          The Personal Data You provide can give You access to
                          different functionalities of the Service that are
                          available to You as a registered user.
                        </li>

                        <li>
                          <span className="fw-bold">
                            For the performance of a contract:
                          </span>
                          {"  "}
                          the development, compliance and undertaking of the
                          purchase contract for the products, items or services
                          You have purchased or of any other contract with Us
                          through the Service.
                        </li>

                        <li>
                          <span className="fw-bold">To contact You:</span>
                          {"  "}
                          To contact You by email, telephone calls, SMS, or
                          other equivalent forms of electronic communication,
                          such as a mobile application's push notifications
                          regarding updates or informative communications
                          related to the functionalities, products or contracted
                          services, including the security updates, when
                          necessary or reasonable for their implementation.
                        </li>

                        <li>
                          <span className="fw-bold">To provide You </span>
                          {"  "}
                          with news, special offers and general information
                          about other goods, services and events which we offer
                          that are similar to those that you have already
                          purchased or enquired about unless You have opted not
                          to receive such information.
                        </li>

                        <li>
                          <span className="fw-bold">
                            To manage Your requests:
                          </span>
                          {"  "}
                          To attend and manage Your requests to Us.
                        </li>

                        <li>
                          <span className="fw-bold">
                            For business transfers:
                          </span>
                          {"  "}
                          We may use Your information to evaluate or conduct a
                          merger, divestiture, restructuring, reorganization,
                          dissolution, or other sale or transfer of some or all
                          of Our assets, whether as a going concern or as part
                          of bankruptcy, liquidation, or similar proceeding, in
                          which Personal Data held by Us about our Service users
                          is among the assets transferred.
                        </li>

                        <li>
                          <span className="fw-bold">For other purposes: </span>
                          {"  "}
                          We may use Your information for other purposes, such
                          as data analysis, identifying usage trends,
                          determining the effectiveness of our promotional
                          campaigns and to evaluate and improve our Service,
                          products, services, marketing and your experience.
                        </li>
                      </ul>
                      <p>
                        We may share Your personal information in the following
                        situations:
                      </p>
                      <ul>
                        <li>
                          <span className="fw-bold">
                            With Service Providers:
                          </span>
                          {"  "}
                          We may share Your personal information with Service
                          Providers to monitor and analyze the use of our
                          Service, to contact You.
                        </li>

                        <li>
                          <span className="fw-bold">
                            For business transfers:{" "}
                          </span>
                          {"  "}
                          We may share or transfer Your personal information in
                          connection with, or during negotiations of, any
                          merger, sale of Company assets, financing, or
                          acquisition of all or a portion of Our business to
                          another company.
                        </li>

                        <li>
                          <span className="fw-bold">With Affiliates:</span>
                          {"  "}
                          We may share Your information with Our affiliates, in
                          which case we will require those affiliates to honor
                          this Privacy Policy. Affiliates include Our parent
                          company and any other subsidiaries, joint venture
                          partners or other companies that We control or that
                          are under common control with Us.
                        </li>

                        <li>
                          <span className="fw-bold">
                            With business partners:
                          </span>
                          {"  "}
                          We may share Your information with Our business
                          partners to offer You certain products, services or
                          promotions.
                        </li>

                        <li>
                          <span className="fw-bold">With other users: </span>
                          {"  "}
                          when You share personal information or otherwise
                          interact in the public areas with other users, such
                          information may be viewed by all users and may be
                          publicly distributed outside.
                        </li>

                        <li>
                          <span className="fw-bold">With Your consent:</span>
                          {"  "}
                          We may disclose Your personal information for any
                          other purpose with Your consent.
                        </li>
                      </ul>
                      <h5
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Retention of Your Personal Data
                      </h5>
                      <p>
                        The Company will retain Your Personal Data only for as
                        long as is necessary for the purposes set out in this
                        Privacy Policy. We will retain and use Your Personal
                        Data to the extent necessary to comply with our legal
                        obligations (for example, if we are required to retain
                        your data to comply with applicable laws), resolve
                        disputes, and enforce our legal agreements and policies.
                      </p>
                      <p>
                        The Company will also retain Usage Data for internal
                        analysis purposes. Usage Data is generally retained for
                        a shorter period of time, except when this data is used
                        to strengthen the security or to improve the
                        functionality of Our Service, or We are legally
                        obligated to retain this data for longer time periods.
                      </p>
                      <h5
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Transfer of Your Personal Data
                      </h5>
                      <p>
                        Your information, including Personal Data, is processed
                        at the Company's operating offices and in any other
                        places where the parties involved in the processing are
                        located. It means that this information may be
                        transferred to — and maintained on — computers located
                        outside of Your state, province, country or other
                        governmental jurisdiction where the data protection laws
                        may differ than those from Your jurisdiction.
                      </p>
                      <p>
                        Your consent to this Privacy Policy followed by Your
                        submission of such information represents Your agreement
                        to that transfer.
                      </p>
                      <p>
                        The Company will take all steps reasonably necessary to
                        ensure that Your data is treated securely and in
                        accordance with this Privacy Policy and no transfer of
                        Your Personal Data will take place to an organization or
                        a country unless there are adequate controls in place
                        including the security of Your data and other personal
                        information.
                      </p>
                      <h5
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Disclosure of Your Personal Data
                      </h5>
                      <h6 className="card-title text-primary mt-4 mb-2 ">
                        Business Transactions
                      </h6>
                      <p>
                        If the Company is involved in a merger, acquisition or
                        asset sale, Your Personal Data may be transferred. We
                        will provide notice before Your Personal Data is
                        transferred and becomes subject to a different Privacy
                        Policy.
                      </p>

                      <h6 className="card-title text-primary mt-4 mb-2 ">
                        Law enforcement
                      </h6>
                      <p>
                        Under certain circumstances, the Company may be required
                        to disclose Your Personal Data if required to do so by
                        law or in response to valid requests by public
                        authorities (e.g. a court or a government agency).
                      </p>

                      <h6 className="card-title text-primary mt-4 mb-2 ">
                        Other legal requirements
                      </h6>
                      <p>
                        The Company may disclose Your Personal Data in the good
                        faith belief that such action is necessary to:
                      </p>
                      <ul>
                        <li>Comply with a legal obligation</li>
                        <li>
                          Protect and defend the rights or property of the
                          Company
                        </li>
                        <li>
                          Prevent or investigate possible wrongdoing in
                          connection with the Service
                        </li>
                        <li>
                          Protect the personal safety of Users of the Service or
                          the public
                        </li>
                        <li>Protect against legal liability</li>
                      </ul>
                      <h5
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Security of Your Personal Data
                      </h5>
                      <p>
                        The security of Your Personal Data is important to Us,
                        but remember that no method of transmission over the
                        Internet, or method of electronic storage is 100%
                        secure. While We strive to use commercially acceptable
                        means to protect Your Personal Data, We cannot guarantee
                        its absolute security.
                      </p>
                      <h4
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Children's Privacy
                      </h4>
                      <p>
                        Our Service does not address anyone under the age of 13.
                        We do not knowingly collect personally identifiable
                        information from anyone under the age of 13. If You are
                        a parent or guardian and You are aware that Your child
                        has provided Us with Personal Data, please contact Us.
                        If We become aware that We have collected Personal Data
                        from anyone under the age of 13 without verification of
                        parental consent, We take steps to remove that
                        information from Our servers.
                      </p>
                      <p>
                        If We need to rely on consent as a legal basis for
                        processing Your information and Your country requires
                        consent from a parent, We may require Your parent's
                        consent before We collect and use that information.
                      </p>

                      <h4
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Links to Other Websites
                      </h4>
                      <p>
                        Our Service may contain links to other websites that are
                        not operated by Us. If You click on a third party link,
                        You will be directed to that third party's site. We
                        strongly advise You to review the Privacy Policy of
                        every site You visit.
                      </p>
                      <p>
                        We have no control over and assume no responsibility for
                        the content, privacy policies or practices of any third
                        party sites or services.
                      </p>

                      <h4
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Changes to this Privacy Policy
                      </h4>
                      <p>
                        We may update Our Privacy Policy from time to time. We
                        will notify You of any changes by posting the new
                        Privacy Policy on this page.
                      </p>
                      <p>
                        We will let You know via email and/or a prominent notice
                        on Our Service, prior to the change becoming effective
                        and update the "Last updated" date at the top of this
                        Privacy Policy.
                      </p>
                      <p>
                        You are advised to review this Privacy Policy
                        periodically for any changes. Changes to this Privacy
                        Policy are effective when they are posted on this page.
                      </p>
                      <h4
                        className="card-title text-primary mt-4 mb-2 "
                        style={{ textTransform: "none" }}
                      >
                        Contact Us
                      </h4>
                      <p>
                        If you have any questions about this Privacy Policy, You
                        can contact us:
                      </p>
                      <ul>
                        <li>
                          By email:{" "}
                          <a href="mailto:boardmembers@beneventoeasthoa.com">
                            boardmembers@beneventoeasthoa.com
                          </a>
                        </li>
                        <li>
                          By visiting this page on our website:{" "}
                          <Link to="https://www.beneventoeast.com">
                            https://www.beneventoeast.com
                          </Link>
                        </li>
                        <li>
                          By phone number:{" "}
                          <a href="tel:615-814-0102">615-814-0102</a>
                        </li>
                        <li>
                          By mail: 5016 Spedale Court, Spring Hill, TN 37174
                        </li>
                      </ul>
                      <p>Privacy Policy for Benevento East </p>
                    </div>
                  </Card.Body>
                  <hr />
                  <Card.Body className="active-faq d-flex justify-content-between pb-0 mb-0">
                    <div className="header-title">
                      <h2 className="card-title text-primary">Rules</h2>
                      <ol class="list-group list-group-numbered">
                        <li class="list-group-item">
                          No Lumber, Brick, stone, concrete or other building
                          material can be stored on property except for building
                          purposes, and only for such length of time as required
                        </li>
                        <li class="list-group-item">
                          No owner may excavate or extract earth from any lot
                        </li>
                        <li class="list-group-item">
                          Mailboxes must be uniform and approved by HOA
                        </li>
                        <li class="list-group-item">
                          Garbage cans shall be placed against home flat and
                          cannot be overflowing with garbage. No garbage shall
                          be placed outside of garbage can. All garbage cans
                          shall be screened by appropriate landscaping or walls
                          to conceal the same from view of neighboring Lots
                        </li>
                        <li class="list-group-item">
                          No elevation changes shall be permitted which will
                          materially affect the surface grade of a Lot
                        </li>
                        <li class="list-group-item">
                          Parking of vehicles on the streets in Benevento East
                          is prohibited except for temporary social events. Cars
                          should not block sidewalks for safety concerns.
                        </li>
                        <li class="list-group-item">
                          No tree in excess of six (6) inches in diameter may be
                          removed from a Lot without the consent
                        </li>
                        <li class="list-group-item">
                          No boats, campers or other recreational vehicles shall
                          be located on any Lot. Boats and Campers can me
                          temporarily parked for loading and unloading only and
                          shall not exceed 48hrs in any given week.
                        </li>
                        <li class="list-group-item">
                          No animals of any kind shall be allowed or maintained
                          on any Lot, except that dogs, cats or other household
                          pets may be kept, provided they are not kept for
                          commercial purposes.
                        </li>
                        <li class="list-group-item">
                          No trailer, basement house, tent, garage, barn or
                          other outbuilding shall be erected or used as either a
                          temporary or permanent residence on any Lot.
                        </li>
                        <li class="list-group-item">
                          All Fences shall be 18" from any property line and
                          shall not be located further forward than 1/3 of the
                          home, and must be equal to any adjacent fence. Black
                          rod iron or Beige Vinyl Privacy Fence. No other fences
                          or fence coverings are permitted.
                        </li>
                        <li class="list-group-item">
                          No sign of any kind shall be displayed on any Lot
                          without approval of the Declarant, except for small
                          realty signs
                        </li>
                        <li class="list-group-item">
                          Unlicensed vehicles shall not be parked in the
                          driveway or any Lot or operated within the
                          subdivision.
                        </li>
                        <li class="list-group-item">
                          Children of residents or their guests shall not be
                          permitted to play in drainage ditches during wet
                          weather.
                        </li>
                        <li class="list-group-item">
                          Exterior television and radio antennas shall not
                          extend more than two (2) feet in height above the
                          ridgeline of the dwelling house roof. Satellite dishes
                          shall not exceed twenty-four (24) inches in diameter.
                          Any installation and placement must he preapproved by
                          HOA. All must not be visible from front of home.
                        </li>
                        <li class="list-group-item">
                          Exposed metal fireplace chimneys are prohibited,
                        </li>
                        <li class="list-group-item">
                          No outside clotheslines or other apparatus for the
                          drying of clothes shall be permitted.
                        </li>
                        <li class="list-group-item">
                          Lot Owners are prohibited from obstructing the free
                          flow of storm surface water drainage and/or diverting
                          and/or changing such drainage flow in any manner
                          resulting in damage or hazard to any other Lot Owner.
                        </li>
                        <li class="list-group-item">
                          All driveways shall be surfaced with poured concrete.
                        </li>
                        <li class="list-group-item">
                          No greater than 15% of the homes may be leased. See
                          leasing restrictions
                        </li>
                        <li class="list-group-item">
                          No storage buildings or outbuildings are permitted
                        </li>
                        <li class="list-group-item">
                          No above ground pools are permitted.
                        </li>
                        <li class="list-group-item">
                          No above ground pools are permitted. Hot-tub Spas are
                          permitted - requires ARC
                        </li>
                        <li class="list-group-item">
                          No utility trailers are allowed to be kept or parked
                          on any lot or the street except thatutility trailers
                          may be kept in the garage with a garage door that
                          remains closed except duringtimes the utility trailer
                          is being driven into and out of the gara
                        </li>
                        <li class="list-group-item">
                          Any playground equipment must be of wood or composite
                          material
                        </li>
                        <li class="list-group-item">
                          Basketball Hoops are allowed, No sandbags or other
                          types of materials may be placed on them. They must be
                          filled with proper recommended materials or placed
                          into the ground. They can be located on back of
                          driveway.
                        </li>
                        <li class="list-group-item">
                          Trampolines are allowed, but must be completely in the
                          rear of the home, not on side, and must be kept in
                          good repair. SHOULD BE ANCHORED.
                        </li>
                        <li class="list-group-item">
                          All pets must remain on Homeowners property if not
                          leashed, and must be on a leash when walked in
                          community
                        </li>
                        <li class="list-group-item">
                          Pergulas must be wooden and behind hime. No metal
                          pergulas, or patio coverings, other than umbrella
                        </li>
                        <li class="list-group-item">
                          No commerical vehicals parked in driveway, except for
                          work be performed.
                        </li>
                        <li class="list-group-item">
                          Grass should be kept mowed and free of weeds along
                          with landscaping beds
                        </li>
                      </ol>
                      <h4 className="card-title text-primary mt-4">
                        Typical Issues this year so far
                      </h4>
                      <ul>
                        <li>Parking on Street</li>
                        <li>
                          Parking on Street Grass Not Mowed or excessive weeds
                        </li>
                        <li>
                          Garbage Cans not against side of home and/or
                          overflowing - Currently rules are for behind the home,
                          however we realize the side is ok. Just not in fron of
                          garage doors. Should be towards rear of home, or
                          behind landscaping.
                        </li>
                        <li>
                          Items left in front yards, driveways or Front porches
                        </li>
                        <li>Trampolines in poor repair</li>
                      </ul>
                      <p>
                        <span className="fw-bold">NOTE:</span> The general rule
                        for proper appearance and well kept landscaping all
                        apply with common sense. We are trying to work with
                        everyone as long as it maintains the look and feel
                        consistant with Benevento East
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="sign-in-from text-center">
                <Link to={"/auth/sign-in"}>
                  <Button
                    variant="primary"
                    type="button"
                    className="btn btn-primary mt-2 fw-semibold text-uppercase w-75"
                  >
                    Agree
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </>
  );
};

export default Privacy;
