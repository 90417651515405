import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";

// Images
import Logo from "../../../assets/images/BE-logo.png";
import login1 from "../../../assets/images/login/1.png";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for show/hide password

  useEffect(() => {
    const savedUsername = localStorage.getItem("rememberedUsername");
    if (savedUsername) {
      setUserName(savedUsername);
      setRememberMe(true);
    }
  }, []);

  const formValidation = () => {
    let isValid = true;

    if (!userName) {
      toast.warn("Username is required");
      isValid = false;
    }

    if (!userPassword) {
      toast.warn("Password is required");
      isValid = false;
    }

    return isValid;
  };

  const submitLogin = async () => {
    try {
      if (formValidation()) {
        const reqBody = {
          email: userName,
          password: userPassword,
        };
        const response = await axios.post(
          "http://164.92.112.96:5000/api/v1/users/signin",
          reqBody
        );
        if (response) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("firstName", response.data.firstName);
          localStorage.setItem("lastName", response.data.lastName);

          if (rememberMe) {
            localStorage.setItem("rememberedUsername", userName);
          } else {
            localStorage.removeItem("rememberedUsername");
          }

          navigate("/");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper className="list-inline m-0 p-0">
                  <ul className="swiper-wrapper list-inline m-0 p-0">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3 w-75 h-75"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Welcome to Benevento East
                      </h2>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <img src={Logo} fluid className="w-50" alt="logo" />
                <Form className="mt-5">
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Address</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Your Address"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group text-start position-relative">
                    <h6 className="form-label fw-bold">Password</h6>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        className="form-control mb-0"
                        placeholder="Your Password"
                        value={userPassword}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                        className="border border-light"
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Check className="form-check d-inline-block m-0">
                      <Form.Check.Input
                        type="checkbox"
                        className="form-check-input"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                      <h6 className="form-check-label fw-bold">Remember Me</h6>
                    </Form.Check>
                  </div>
                  <Button
                    variant="primary"
                    type="button"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    onClick={submitLogin}
                  >
                    Sign in
                  </Button>
                  <p className="mt-5" style={{ textTransform: "none" }}>
                    By signing in to your account, you are agreeing to our{" "}
                    <Link to="/auth/privacy&policy">Privacy and Policy.</Link>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </>
  );
};

export default SignIn;
