import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const TableViolations = () => {
  const data = [
    {
      Reference: "000001",
      CreatedBy: "Thomas Hadden",
      Type: "ARC",
      Description: "Remodel of parking",
      DateCreated: "03-04-24",
      DateClosed: "04-04-24",
      Status: "Closed",
    },
    {
      Reference: "000002",
      CreatedBy: "5000 Moretto Court",
      Type: "ARC",
      Description: "Roof Fixes",
      DateCreated: "05-01-24",
      DateClosed: "--------",
      Status: "Ongoing",
    },
  ];

  const [Add, setAdd] = useState(data);
  const [addFormData] = useState({
    Reference: "",
    CreatedBy: "",
    Type: "",
    Description: "",
    DateCreated: "",
    DateClosed: "",
    Status: "",
  });

  const addNewValue = () => {
    setAdd(Add.concat(data[1]));
  };

  const handleAddFormChanges = (event) => {
    event.preventDefault();
    const Reference = event.target.closest("tr").getAttribute("dataReference");
    const tdElem = event.target.closest("tr").querySelectorAll("td");
    const obj = {
      Reference: Reference,
    };
    Array.from(tdElem, (elem) => {
      if (elem.getAttribute("name") !== null) {
        obj[elem.getAttribute("name")] = elem.innerText;
      }
      return null;
    });
    const newArrIndex = Add.findIndex((item) => {
      if (item.Reference === Reference) {
        return item;
      } else {
        return null;
      }
    });
    Add[newArrIndex] = obj;
    setAdd(Add);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    let newContact = {
      Reference: `000${Add.length + 1}`,
      Name: addFormData.Name,
      Age: addFormData.Age,
      Company: addFormData.Company,
      Country: addFormData.Country,
      City: addFormData.City,
    };
    const newContacts = [...Add, newContact];
    setAdd(newContacts);
  };

  return (
    <>
      <div Reference="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">Issues / Violations</h3>
                  <p className="text-white">lorem ipsum</p>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div>
                  <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                    Issues & Violation lists
                  </h3>
                </div>
                <Card.Body className="pt-0">
                  <div Reference="table" className="table-editable">
                    <span className="table-add float-end mb-3 me-2">
                      <Button className="btn btn-sm btn-success d-flex align-items-center">
                        <i
                          className="material-symbols-outlined me-1 md-18"
                          onClick={addNewValue}
                        >
                          add
                        </i>
                        Create New
                      </Button>
                    </span>
                    <table className="table table-bordered table-responsive-md table-striped text-center">
                      <thead>
                        <tr>
                          <th>Reference</th>
                          <th>Created by</th>
                          <th>Type</th>
                          <th>Description</th>
                          <th>Date created </th>
                          <th>Date closed</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Add.map((adds, props) => (
                          <tr key={props} dataReference={adds.Reference}>
                            <td
                              name="Reference"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Reference}
                            </td>
                            <td
                              name="CreatedBy"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.CreatedBy}
                            </td>
                            <td
                              name="Type"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Type}
                            </td>
                            <td
                              name="Description"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Description}
                            </td>
                            <td
                              name="DateCreated"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.DateCreated}
                            </td>
                            <td
                              name="DateClosed"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.DateClosed}
                            </td>
                            <td
                              name="Status"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Status}
                            </td>

                            <td>
                              <a href="">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Update</Tooltip>}
                                >
                                  <i className="material-symbols-outlined me-1 md-18 text-primary">
                                    assignment
                                  </i>
                                </OverlayTrigger>{" "}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TableViolations;
