// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dark .css-yk16xz-control{
    background-color: #1e2745;
    border-color:#252e4b;
}
.dark .css-1pahdxg-control{
    background-color: #1e2745;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/email/email-compose.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,oBAAoB;AACxB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.dark .css-yk16xz-control{\n    background-color: #1e2745;\n    border-color:#252e4b;\n}\n.dark .css-1pahdxg-control{\n    background-color: #1e2745;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
